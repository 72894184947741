import React, { useEffect } from "react";
import Logo from "/Users/bhargav/Documents/heena/Quick-Gold-Buyer/QGB-Website/src/images/Group.png";
// import { FaWhatsappSquare, FaInstagramSquare, FaFacebookSquare, FaYoutubeSquare } from "react-icons/fa";
import { FaArrowUp, FaWhatsapp, FaInstagram, FaFacebookF, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollButton = document.querySelector('.scroll-to-top');
            if (scrollButton) {
                if (window.scrollY > 200) {
                    scrollButton.style.display = 'block';
                } else {
                    scrollButton.style.display = 'none';
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <footer className="text-center text-lg-start bg-body-tertiary text-muted pt-3 mt-5 footer">
                <section>
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <img src={Logo} width="50%" alt="image" />
                                <p className="mt-2">
                                    we have earned an extraordinary reputation in offering unbiased and impeccable honesty, transparency, and the best gold price in the market.
                                </p>
                            </div>

                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Quick Link
                                </h6>
                                <p>
                                    <Link to="/aboutUs" className="text-reset">About us</Link>
                                </p>
                                <p>
                                    <Link to="/service" className="text-reset">Our service</Link>
                                </p>
                                <p>
                                    <Link to="/FAQ" className="text-reset">FAQ</Link>
                                </p>
                                <p>
                                    <Link to="/calculator" className="text-reset">Calculator</Link>
                                </p>
                            </div>

                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Quick Link
                                </h6>
                                <p>
                                    <Link to="/termcondition" className="text-reset">Terms & Conditions</Link>
                                </p>
                                <p>
                                    <Link to="/privacyPolicy" className="text-reset">Privacy Policy</Link>
                                </p>
                            </div>

                            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">Store details</h6>
                                <p><i className="fas fa-phone"></i> +91 84888 84401</p>
                                <p><i className="fas fa-home"></i>102, Gala Hub ANX, Nr. Basant Society, 1, Gala Gymkhana Rd, South Bopal, </p>
                                <p><i className="fas fa-print"></i>Ahmedabad, Gujarat 3880058</p>
                            </div>

                            <div className="col-md-4 col-lg-12 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    follow us
                                </h6>
                        
                                <div>
                                    <a href="https://api.whatsapp.com/send/?phone=%2B918488884401&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer" className="text-reset social-icon-whatsApp"><FaWhatsapp /></a>
                                    <a href="https://www.instagram.com/quick.gold.buyer?igsh=bmppczdhZ21sbHpv" target="_blank" rel="noopener noreferrer" className="text-reset social-icon-instagram"><FaInstagram /></a>
                                    <a href="https://www.facebook.com/profile.php?id=61554853066319&mibextid=2JQ9oc" target="_blank" rel="noopener noreferrer" className="text-reset social-icon-facebook"><FaFacebookF /></a>
                                    <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer" className="text-reset social-icon-youtube"><FaYoutube /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="row d-flex justify-content-center p-4" style={{ background: "rgba(0, 0, 0, 0.05)" }}>
                    © 2024 Copyright Quick Gold Buyer 2024. All Rights Reserved.
                </div>

                <button className="scroll-to-top" onClick={scrollToTop}>
                    <FaArrowUp />
                </button>
            </footer>

        </>
    )
}

export default Footer