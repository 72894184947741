import React from "react";
import Sideimage from '../images/about.jpg'

const About = () => {

    return (
        <>
            <div className="container mt-5" id="aboutUs">
                <div className="d-flex justify-content-center mt-5">
                    <h1 className="All-heading">About Us</h1>
                </div>
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-6 p-3">
                        <p style={{ textAlign: "justify" }}>
                            we have earned an extraordinary reputation in offering unbiased and impeccable honesty, transparency, and the best gold price in the market.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            We have been the most preferred gold buyers for the customer who need instant cash for gold jewellry. We guarantee 110% customer satisfaction with absolute transparency. We have state-of-the-art modern german technology Karatmeter, which ensures our customers get the most accurate and reliable valuation for their gold. Quick glod buyers take pride in offering only the very best and top-quality service. We never disappoint our customers.
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 d-flex justify-content-center">

                        <img src={Sideimage} alt="side image" className="img-fluid" />

                    </div>
                </div>
            </div>

        </>
    )
}

export default About