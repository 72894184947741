import React from "react";
import Mainimage from '../images/Frame 26.png'


const Privacy = () => {
    return (
        <>
            <div className="container-fluid m-0 p-0 mt-5">
                <img src={Mainimage} alt="mainImage" width="100%" />
            </div>
            <div className="container mt-5">
                <p>
                    Quick Gold Buyer website is the licensed brand of Jewel Industry With ISO Certified and BIS Hallmark. We respect Govt policies and your privacy. As a visitor to the site you are advised to read the Privacy Policy carefully. By accessing the service provided by the Quick Gold Buyer website you agree to the collection and use of your data/information in relation to this policy. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our service. The personal information that we collect is used for providing and improving the service. We/QGB and associates will not share your information with anyone except as described in Privacy Policy.
                </p>
                <p>When you are using our services, you trust us with your information.QGB/Quick Gold Buyer website understands this is a big responsibility and work hard to protect your information.</p>
                <p>The terms used in this Privacy Policy have the same meanings as in our Terms and conditions, which is accessible at Quickgoldbuyer.com website unless otherwise defined this Privacy Policy.</p>

                <h5><u>Information Collection & Use</u></h5>
                <p>For a better experience, while using QGB/Quickgoldbuyer.com service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy.</p>
                <p>Quick Gold Buyer website does use third party services that may collect information used to identify you. Link to privacy policy of third party service providers used by the website.</p>

                <h5><u>Log Data:</u></h5>
                <p>We want to inform you that whenever you use our service, in a case of an error in app/web, we collect data
                    and information (through third party products) on your phone called logged data. This log data may include
                    information such as your device Internet Protocol (IP) address, device name, operating system version, the configuration of the app when utilising our Service, the time and date of your use the Service, and other statistics.</p>


                <h5><u>Cookies:</u></h5>
                <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers.
                    These are sent to your browser from the website that you visit and are stored on your devices internal memory.
                    The app/web may use third party code and libraries that use cookies to collect information and improve their services.
                    You have the option to either accept or refuse these cookies and know when cookie is being sent to your device.
                    If you choose to refuse our cookies, you may not be use some portion of this service.
                </p>

                <h5><u>Service Providers:</u></h5>
                <p>We may employ third party companies and individuals due to the following reasons.</p>
                <ul>
                    <li>To facilitate our service</li>
                    <li>To provide the Service on our behalf</li>
                    <li>To perform Service-related services; or</li>
                    <li>To assist us in analysing how our Service is used</li>

                </ul>

                <p>
                    Quick Gold Buyer website want to inform users of this Service that these third parties have access to your Professional Information. The reason is to perform the tasks assigned to them on our behalf.
                    However, they are obligated not to disclose or use the information for any other purpose.
                </p>

                <h5><u>Security:</u></h5>
                <p>We value your trust in providing us your Personal Information, Thus we are striving to use commercially
                    acceptable means of protecting it. But remember that no method of transmission over the internet,
                    or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                <h5><u>Links to Other Site:</u></h5>
                <p>The Service may contain links to other sites. If you click on the third party link,
                    you will be directed to that site. These external sites are not operated by us and we strongly advice you to review the Privacy Policy of these websites.
                    We have no control over third parties policies, practices or services.</p>


                <h5><u>Children’s Privacy:</u></h5>
                <p>The Services do not address anyone under the age of 18. If you are a parent or guardian and are aware that
                    your child has provided us with personal information, please contact us so that we will be able to do necessary
                    actions.</p>


                <h5><u>Changes & Revisions:</u></h5>
                <p>We may update our Privacy Policy from time to time. Hence, you are advised to check our website
                    periodically to see recent changes.</p>
            </div>




        </>
    )
}

export default Privacy