import React from "react";
import Handimage from '../images/image-removebg-preview (14) 2.png';
import Handimage2 from '../images/image-removebg-preview (15) 2.png';
import Lockimage from '../images/lock.png';
import Heartimage from '../images/heart.png';
import Graphimage from '../images/grapf.png';
import Starimage from '../images/star.png';
import Boximage from '../images/box.png';
import Flagimage from '../images/flag.png';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const HowWork = () => {
   
    window.addEventListener(
      "scroll",
      () => {
        document.body.style.setProperty(
          "--scroll",
          window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
        );
      },
      false
    );      
    return (
        <>
            <div className="container-fluid mt-5 mb-5">
                <h6 style={{ borderBottom: '1px solic darkblue', color: 'darkblue' }}><b>How It Works:</b></h6>
                <div className="row  mt-5">
                    <div className="col-lg-3">
                        <img src={Handimage} alt="hand image" width="100%" className="animate-on-scroll"/>
                    </div>
                    <div className=" col-lg-6 animate-on-scroll" >
                        <div className="row d-flex justify-content-center mx-2">
                            <div className="col-sm-6 howItWork-box" style={{ background: "#FFD700" }}>
                                <h6>1. Submit Your Details:</h6>
                                <p>Fill out our online form with your details and a description of the gold items you wish to sell on website.</p>
                            </div>
                            <div className="col-sm-6 howItWork-box">
                                <h6>2. KYC Verification At Shop:</h6>
                                <p>Our KYC software will verify your information securely and swiftly</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center  mx-2">
                            <div className="col-sm-6 howItWork-box">
                                <h6>3. Get Your Quote</h6>
                                <p>Receive a fair and competitive quote for your gold items On Our Software.</p>
                            </div>
                            <div className="col-sm-6 howItWork-box" style={{ background: "#FFD700" }}>
                                <h6>4. Receive Payment:</h6>
                                <p>Once we receive and verify your gold, your payment will be processed promptly</p>
                            </div>
                        </div>
                    </div>
                    <div className=" col-lg-3 ">
                        <img src={Handimage2} width="100%" className="animate-on-scroll" />
                    </div>

                </div>

                <h3 className="mt-5" style={{ borderBottom: '1px solic darkblue', color: 'darkblue', textAlign: "center" }}> <b>Our Core Values</b></h3>
                <div className="row d-flex justify-content-center align-items-center mt-5 m-5">
                    <div className="col-sm-12 col-md-4 col-lg-2  d-flex justify-content-center align-items-center flex-column">
                       <img src={Graphimage} width="50%" alt="image"  />
                       <h2 className="h2-responsive mt-5">Expectations</h2>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-2  d-flex justify-content-center align-items-center flex-column">
                        <img src={Heartimage} width="50%" alt="image" style={{ marginBottom: "4rem"}} />
                        <h2 className="h2-responsive">Honesty</h2>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-2  d-flex justify-content-center align-items-center flex-column">
                        <img src={Starimage} width="50%" alt="image"className="mb-5" />
                        <h2 className="h2-responsive">Satisfaction</h2>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-2 d-flex justify-content-center align-items-center flex-column">
                        <img src={Lockimage} width="50%" alt="image" className="mb-2"/>
                        <h2 className="h2-responsive">Transparency</h2>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-2  d-flex justify-content-center align-items-center flex-column">
                        <img src={Boximage} width="50%" alt="image" style={{ marginBottom: "4rem"}}/>
                        <h2 className="h2-responsive">EthicalTrade</h2>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-2 d-flex justify-content-center align-items-center flex-column">
                        <img src={Flagimage} width="50%" alt="image"/>
                        <h2 className="h2-responsive">Integrity</h2>
                    </div>
                </div>
                {/* <div className="row d-flex justify-content-center align-items-center m-5">
                    <div className="col-6 col-sm-4 col-md-2 col-lg-2  d-flex justify-content-center align-items-center">
                      <h2 className="h2-responsive">Expectations</h2>
                    </div>
                    <div className="col-6 col-sm-4 col-md-2 col-lg-2  d-flex justify-content-center align-items-center">
                    <h2 className="h2-responsive">Honesty</h2>
                    </div>
                    <div className="col-6 col-sm-4 col-md-2 col-lg-2  d-flex justify-content-center align-items-center">
                    <h2 className="h2-responsive">Satisfaction</h2>
                    </div>
                    <div className="col-6 col-sm-4 col-md-2 col-lg-2  d-flex justify-content-center align-items-center">
                    <h2 className="h2-responsive">Transparency</h2>
                    </div>
                    <div className="col-6 col-sm-4 col-md-2 col-lg-2  d-flex justify-content-center align-items-center">
                    <h2 className="h2-responsive">EthicalTrade</h2>
                    </div>
                    <div className="col-6 col-sm-4 col-md-2 col-lg-2  d-flex justify-content-center align-items-center">
                    <h2 className="h2-responsive">Integrity</h2>
                    </div>
                </div> */}
               
                {/* <div className="table-responsive">
                    <Table className="table">
                    <Thead>
                           <Tr>
                                <Th>
                                    <img src={Graphimage} className="img-fluid" style={{ maxHeight: "100%" }} alt="Graph" />
                                </Th>
                                <Th>
                                    <img src={Heartimage} className="img-fluid" style={{ maxHeight: "100%" }} alt="Heart" />
                                </Th>
                                <Th>
                                    <img src={Starimage} className="img-fluid" style={{ maxHeight: "100%" }} alt="Star" />
                                </Th>
                                <Th>
                                    <img src={Lockimage} className="img-fluid" style={{ maxHeight: "100%" }} alt="Lock" />
                                </Th>
                                <Th>
                                    <img src={Boximage} className="img-fluid" style={{ maxHeight: "100%" }} alt="Box" />
                                </Th>
                                <Th>
                                    <img src={Flagimage} className="img-fluid" style={{ maxHeight: "100%" }} alt="Flag" />
                                </Th>
                            </Tr>
                           </Thead>
                        <Tbody>
                          
                            <Tr>
                                <Td>
                                    <h2 className="h2-responsive">Expectations</h2>
                                </Td>
                                <Td>
                                    <h2 className="h2-responsive">Honesty</h2>
                                </Td>
                                <Td>
                                    <h2 className="h2-responsive">Satisfaction</h2>
                                </Td>
                                <Td>
                                    <h2 className="h2-responsive">Transparency</h2>
                                </Td>
                                <Td>
                                    <h2 className="h2-responsive">Ethical Trade</h2>
                                </Td>
                                <Td>
                                    <h2 className="h2-responsive">Integrity</h2>
                                </Td>

                            </Tr>
                        </Tbody>
                    </Table>
                </div> */}


            </div>



        </>
    )
}

export default HowWork