import React from "react";
import { FaLocationDot } from "react-icons/fa6";


const Location = () => {
    return (
        <>
            <div className="container-fluid mt-5 location m-0">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-sm-3 col-md-3 col-lg-3 text-center">
                        <h1>1776</h1>
                        <p>Fair value</p>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 text-center">
                        <h1 className="location-name">  <FaLocationDot /><span> <u>AT SOUTH BOPAL</u></span></h1>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 text-center">
                        <h1>4853</h1>
                        <p>Happy customer</p>
                    </div>
                </div>
            </div>

            <div className="container trustedBuyer mt-5">
                <div className="row d-flex justify-content-center align-items-center p-5">
                    <div>
                        <h1 className="location-name mb-5"><u>Your Trusted Buyer</u></h1>
                    </div>
                   <div className="text-justify">
                   <p>
                        Being the trusted gold buyer, we offer the best price for your gold. We purchase gold as well as gold-related items in all forms. So, if you are searching to sell your gold, wish to release your pledge gold loan for the spot cash, or even want to convert gold to cash, our team will ensure that you receive the price the market cannot match. Our services provide all the customers with the feeling of satisfaction after they interact with our team. The team members of QGB are committed to you and ensure you the best prices for your gold. We treat our clients with dignity, which makes us special. Our reviews talk about most of our services. Visit any of our branches to experience the services that are delivered by our team. Our branches are open all days except on sundays.
                    </p>
                    <p>
                        We are happy to serve you. Also, we assist you in releasing the pledged gold from pawn brokers/banks. We ensure that the customers are assured of the perfect value they expect from any jewelry buyer.
                    </p>
                   </div>
                </div>
            </div>
        </>

    )
}

export default Location