import React from "react";
import Mainimage from '../images/Frame 25.png'


const TermCondition = () => {
    return (
        <>
            <div className="cotainer-fluid mt-5">
                <img src={Mainimage} alt="main image" width="100%" />

            </div>
            <div className="container mt-5">
                <ul>
                    <li>
                        This website can be use only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you/user are minor, your age below 18 years you may use quickgoldbuyer.com for any transaction ie sell/buy or visit as guest only with the presence of parent or guardian.
                    </li>
                    <li>
                        When you visit Quick Gold Buyer website you will be update valid contact number/Phone number, Email id for placing order as we can communicate with you by SMS, Phone call, or posting notices or other mode of communication including Transactional/Promotional sms, WhatsApp messages with respect to serve you better.
                    </li>
                    <li>
                        We will not be responsible for any business/transactional loss/profit, data, goodwill or other expenditure when you using the Quick Gold Buyer website.
                    </li>
                    <li>
                        User will be responsible for maintaining the confidentiality of account, password and for restricting access to your computer to prevent unauthorized access to your account. Please ensure that the details you provided us are correct and complete and inform immediately to us if any changes in address.
                    </li>
                    <li>
                        Quick Gold Buyer website reserves the right to refuse access to the website, terminate accounts, remove or edit content at any time without notice to you.
                    </li>
                    <li>
                        You may not use Quick Gold Buyer website for fraudulent purposes, or in connection with a criminal offence, misleading or any other unlawful activity.
                    </li>
                    <li>
                        All the content included on the website, text, graphics, logo, icon, images, audio/video clip and software is the property of QGB, and it’s protected by Indian copyright.
                    </li>
                    <li>
                        We reserve the right to make changes of our website, policies, terms and conditions at any time without any prior intimation.
                        We (“QGB”, Quick Gold Buyer website) will not be responsible for any delay or failure in our service due to national calamities, riot, and issues beyond our control.
                    </li>
                    <li>
                        This Quick Gold Buyer website is operated by QGB Officials.
                    </li>
                    <li>
                        Sales & Purchase rate(Pricing policy) updated by Quick Gold Buyer website Head office team based on the current gold/metal rate. Prices of Gold sales and purchase are subject to change as per market fluctuations without any prior intimation.
                    </li>
                    <li>
                        If there is a conflict or contradiction between the provisions of website terms and conditions you may please inform to our customer care and stop using these website till clarification received.
                    </li>
                </ul>

                <h3><u>WE BUY GOLD (SELL your gold in Quick Gold Buyer website) Terms & Use</u></h3>

                <ul>
                    <li>
                        All payment would be done by IMPS,RTGS,NEFT mode only.
                    </li>
                    <li>
                        Sellers need to submit/upload proper documentation before process.

                    </li>
                    <li>
                        Company may reserve the right to reject transactions without prior intimation.
                    </li>
                    <li>
                        Once transactions completed, gold jewelry will be sent to hub for melting and customer cannot claim for return of the ornaments given for sale
                    </li>
                    <li>
                        If the given article is found to be fake or spurious or of lesser purity, or stolen, the company reserves the right to initiate appropriate investigation/criminal action . On account of these, if any loss incurred by the company,
                        the seller shall be solely liable and responsible for the same and to bear the loss.
                    </li>
                    <li>
                        The employees of the company will have the right to verify the purity of the ornaments offered for sale. They may cut the ornaments if required to verify the purity in front of seller.
                    </li>
                    <li>
                        All transactions shall be executed during office working hours/business day only (Monday to Saturday : 10:00AM to :7:00PM)
                    </li>
                    <li>
                        Any claims and disputes arising or in connection with the transactions or the performance of the covenants thereof shall be settled by arbitration by the sole arbitrator to be appointed by the company. The place of arbitration shall be in Ahemdabad. The arbitration shall be conducted under the provision of the Arbitration and Conciliation Act 1996 or any statutory modification or re-enactment thereof for the time being in force and the award of such arbitrator shall be final and binding upon the customer and the company.
                    </li>
                    <li>
                        The valuation of the articles shall be done by an assayer authorised and appointed by the company and the amount shall be determined by the company on the basis of the value of the offered articles as set out in the valuation report supplied by the assayer. This value shall be subject to the market price of the offered articles from time to time.
                    </li>
                    <li>
                        quickgoldbuyer.com App/Web will not accept any of the requests (Sell/buy) from below 18 years age user.
                    </li>
                    <li>
                        Company executive may visit applicant address based on proper order ID.
                    </li>
                    <li>
                        The company reserves the right to amend the Terms and Conditions by updating the same in website Gold Ownership Declaration :
                    </li>
                </ul>
                <p>
                    I am the true and beneficial owner of the gold ornaments offered by me. I have acquired the said jewelry in a lawful manner and there is no claim or dispute in respect of the ownership of the same from any person living or legal heir/s of any deceased person.
                </p>
                <h5><u>Warning.</u></h5>
                <p>Attempting to sell stolen or spurious articles is a criminal offence and legal action would be taken as per law. Also suspicious transactions will be reported to the concerned Govt authority.</p>
                <h5><u>Disclaimer</u></h5>
                <p>You acknowledge and undertake that you are accessing the service on the website and transacting at your own risk. You acknowledge and undertake that you will use the website to order products only for your personal use and not for business purposes.</p>
            </div>
        </>
    )
}

export default TermCondition