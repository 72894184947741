import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './component/header';
import Main from './pages/mainhome';
import Footer from './component/footer';
import TermCondition from './pages/termCondition';
import Privacy  from './pages/privacyPolicy'

function App() {
  return (
    <>
    <Router basename="/">
       
          <Header />

          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/termcondition" element={<TermCondition />} />
            <Route path="/privacyPolicy" element={<Privacy />} />
          </Routes>

          <Footer />
      
      </Router>
    </>
  );
}

export default App;
