import React from "react";

import { MdOutlinePhoneInTalk } from "react-icons/md";


const Lastsection = () => {
    return (
        <>
            <div className="container mt-5 lastsection">
                
                    <h1 style={{ color: '#0C0F60' }}><u>Queries? We’ll Love to help</u></h1>
                    <button className="contact-btn"><MdOutlinePhoneInTalk />Contact:  +91 84888 84401</button>

            </div>

        </>
    )
}

export default Lastsection