import React from "react";
import Logo from '../images/qgb-logo-removebg-preview.png';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
const Header = () => {
    return (
        <>

            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="#home">
                        <img src={Logo} width="300"
                            height="100" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto ms-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="#aboutUs">About us</Nav.Link>
                            <Nav.Link href="#whychoose">Why Choose Us</Nav.Link>
                            <Nav.Link href="#service">Service</Nav.Link>
                            <Nav.Link href="#FAQ">FAQ</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link href="#Projects"> <button className="contact-btn">Contact: +91 8488884401</button></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header