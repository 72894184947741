import React, { useReducer, useState } from "react";
import Sideimage from '../images/Ellipse 23.png'
import Calculatorimage from '../images/image-removebg-preview (3) 1.png'
import CalculateImage from '../images/image-removebg-preview (12) 1.png';
import axios from "axios";

const goldurl = "https://sellmyjewel.com/api/excellent-gold-buy-live-price";

const Calculate = () => {
    // const [showModal, setShowModal] = useState(false);
    const [gram, setGram] = useState('');
    const [carat, setCarat] = useState('');
    const [calculatedValue, setCalculatedValue] = useState('')


    // const handleCalculate = () => {
    //     console.log("heena open")
    //     setShowModal(true);
    // };

    // const handleCloseModal = () => {
    //     setShowModal(false);
    // };
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const handleChange = async (event) => {
        event.preventDefault();
        try {
            const data = {
                gram: gram,
                carat: carat
            }
            const result = await axios.post(`${goldurl}`, JSON.stringify(data), config);
            if (result.data.status == 200) {
                // localStorage.setItem('gold_details', JSON.stringify(result.data.data));
                setCalculatedValue(result.data.result);
            }

        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };

    const handleChangeCarat = (event) => {
        setCarat(event.target.value);
    };

    const handleChangeGram = (event) => {
        setGram(event.target.value);
    };


    return (
        <div className="container mt-5" id="calculator">
            <div className="d-flex justify-content-center">
                <h1 className="All-heading">QGB Calculator</h1>
            </div>
            <div className="row d-flex justify-content-center mt-5">
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <p>
                        The QGB calculator gives you the ability to sell your gold without any interference from your local jeweler.  Whether it is selling for an investment mode or for any essential cash concern, you can evaluate how much your gold is cost from the comfort of your home
                    </p>
                    <p>Some are its benefits like:</p>
                    <ul>
                        <li>Available 24 x 7</li>
                        <li>Secure</li>
                        <li>No any charges for calculation</li>
                        <li>Accurate results</li>
                        <li>Unlimited usages</li>
                        <li>Safe</li>
                    </ul>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-6">
                    <img src={Sideimage} alt="image" className="calculate-main-image round mx-auto d-block" />
                    <img src={Calculatorimage} alt="image" className="calculator-image mx-auto d-block" />
                    <p className=" pt-5" style={{marginTop:'6rem'}}> <b>We provide you the best price than anyone else</b></p>
                </div>
            </div>

            <div className="row d-flex justify-content-center calculate-form">
                <div className="col-sm-12 col-lg-6 col-md-12">
                    <div className="card calculate-goldvalue-form">
                        <h4 className="text-center">Calculate Your Old Gold Value</h4>
                        <form className="p-3">
                            <div className="d-flex">
                                <div className="form-check">
                                    <input type="radio" id="18" name="carat" value="18" className="form-check-input" onChange={handleChangeCarat} />
                                    <label className="form-check-label">18</label>
                                </div>

                                <div className="form-check mr-3">
                                    <input type="radio" id="20" name="carat" value="20" className="form-check-input" onChange={handleChangeCarat} />
                                    <label className="form-check-label">20</label>
                                </div>
                                <div className="form-check mr-3">
                                    <input type="radio" id="22" name="carat" value="22" className="form-check-input" onChange={handleChangeCarat} />
                                    <label className="form-check-label">22</label>
                                </div>
                                <div className="form-check mr-3">
                                    <input type="radio" id="24" name="carat" value="24" className="form-check-input" onChange={handleChangeCarat} />
                                    <label className="form-check-label">24</label>
                                </div>

                            </div>

                            <div className="form-group">
                                <label htmlFor="gram">Gram:</label>
                                <input type="text" id="gram" name="gram" className="form-control" value={gram} onChange={handleChangeGram} />
                            </div>
                            <div className="d-flex mt-2">
                                <button className="btn btn-primary" onClick={handleChange}>Calculate</button>
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="value">Value:</label>
                                <input type="text" id="value" className="form-control" value={calculatedValue}
                                    readOnly />
                            </div>
                            <p className="mt-3 text-center">All rights reserved @Quick Gold Buyer . com</p>
                        </form>
                    </div>
                </div>

                <div className="col-sm-12 col-lg-6 col-md-12">
                    <img src={CalculateImage} className="img-fluid" alt="Calculate Image" />
                </div>
            </div>


            {/* Modal */}
            {/* {showModal && (
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Email validation</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseModal}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <label>Email:</label>
                                <input type="text" name="email" id="email" /><br /><br />
                                <label>otp:</label>
                                <input type="text" name="otp" id="otp" />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={handleCloseModal}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}
        </div>
    )
}
export default Calculate