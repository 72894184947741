import React from "react";
import Serviceimage1 from "../images/Rectangle 88.png"
import Serviceimage2 from '../images/Rectangle 90.png'
import Serviceimage3 from '../images/Rectangle 93.png'
import Serviceimage4 from '../images/Rectangle 95.png'

const Services = () => {
    return (
        <>
         <div className="container-fluid mt-5" id="service">
            <div className="d-flex justify-content-center">
                <h1 className="All-heading">Our Services</h1>
            </div>
            <div className="row justify-content-center mt-5">
                <div className="col-lg-2 col-sm-6 col-md-6 service-div p-0">
                    <img src={Serviceimage4} width="100%" alt="ring image" />
                </div>
                <div className="col-lg-2 col-sm-6 col-md-6 service-div">
                    <h6>Invest In Gold</h6>
                    <p>QGB are also not known to give a good buy-back rate compared to bullion merchants. They do not just buy it back buy are also believed to give better buy back prices.</p>
                </div>
                <div className="col-lg-2 col-sm-6 col-md-6 service-div p-0">
                    <img src={Serviceimage2} width="100%" alt="gold image" />
                </div>
                <div className="col-lg-2 col-sm-6 col-md-6 service-div">
                    <h6>Release Pledged Gold</h6>
                    <p>QGB stands by our promise of almost instant cash in hand for your valuable gold. Our fast paperwork process has no hidden complicated query, and the procedure takes less time.</p>
                </div>
            </div>
            <div className="row justify-content-center mt-3">
                <div className="col-lg-2 col-sm-6 col-md-6 service-div">
                    <h6>Invest In Gold</h6>
                    <p>QGB are also not known to give a good buy-back rate compared to bullion merchants. They do not just buy it back buy are also believed to give better buy back prices.</p>
                </div>
                <div className="col-lg-2 col-sm-6 col-md-6 service-div p-0">
                    <img src={Serviceimage3} width="100%" alt="ring image" />
                </div>
                <div className="col-lg-2 col-sm-6 col-md-6 service-div">
                    <h6>Release Pledged Gold</h6>
                    <p>QGB stands by our promise of almost instant cash in hand for your valuable gold. Our fast paperwork process has no hidden complicated query, and the procedure takes less time.</p>
                </div>
                <div className="col-lg-2 col-sm-6 col-md-6 service-div p-0">
                    <img src={Serviceimage1} width="100%" alt="gold image" />
                </div>
            </div>
        </div>

        </>
    )
}

export default Services