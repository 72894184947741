import React,{useEffect} from "react";
import { GiNecklaceDisplay,GiMicroscope } from "react-icons/gi";
import { FaHandHoldingUsd } from "react-icons/fa";

const Workprocess = () => {
    
    return (<>
        <div className="container mt-5">
            <div className="d-flex  justify-content-center">
                <h1 className="All-heading">Our Work Process</h1>
            </div>
        </div>
        <div className="container-fluid mt-5" style={{background:'#ffffc7', border:"2px solid gold"}}>
            <div className="row d-flex justify-content-center align-items-center mt-5">
                <div className="col-sm-12 col-md-4 col-lg-4 text-center" >
                    <div className="">
                        <GiNecklaceDisplay className="work-icon" />
                    </div>
                    <h2>Bring Your Gold </h2>
                    <p className="Workprocess-peragraph">
                        We accept Gold in any and every form! If you want your gold to earn for you, bring your gold to us irrespective of whether it is broken, used, or unused. We also deal in silver and diamonds. Bring any of these to any of our branches, and take home that day’s current online rates for your valuables.
                    </p>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 text-center">
                    <div className="">
                        <GiMicroscope className="work-icon"/>
                    </div>
                    <h2>Gold Testing Machine</h2>
                    <p className="Workprocess-peragraph">We are for a reason one of the most trusted and the best gold buyers in Hyderabad. Your gold will be measured in your presence by our trained professional staff members. We use a Karat Meter which is a scientific device that assesses the purity of the gold placed in it with X-ray technology</p>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 text-center">
                    <div className="">
                   < FaHandHoldingUsd className="work-icon"/>
                        
                    </div>
                    <h2>Immediate Payment</h2>
                    <p className="Workprocess-peragraph">After the evaluation of your valuables is done, our staff will state an on-spot offer for the same. As soon as, you accept the offer and the deal is sealed, we immediately complete the payment for the sale. You instantly get the cash for gold or other valuables that you have sold us.</p>
                </div>
            </div>
        </div>
    </>
    )
}

export default Workprocess