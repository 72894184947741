import React, { useState, useEffect } from "react";
import Slideimage1 from '../images/Component 26.png';
import Slideimage2 from '../images/Property 1=Frame 14.png';
import Slideimage3 from '../images/Property 1=Frame 18.png';
import Slideimage4 from '../images/Frame 22.png';

const Home = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const images = [
        Slideimage1,
        Slideimage2,
        Slideimage3,
        Slideimage4
    ];

    const totalSlides = images.length;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
        }, 3000);

        return () => clearInterval(interval);
    }, [totalSlides]);
    return (
        <>
            <div className="container-fluid p-0">
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Slide ${index + 1}`}
                        width="100%"
                        className={`img-fluid slide ${index === currentSlide ? 'active' : ''}`}
                    />
                ))}
            </div>
        </>
    );
};

export default Home;
