import React, { useEffect } from "react";


const FAQ = () => {

    return (
        <>

            <section className="bg-light py-3 py-md-5" id="FAQ" >
                <div className="container">
                    <div className=" d-flex justify-content-center">
                        <h1 className="All-heading">FAQs</h1>
                    </div>
                    <div className="row mt-5 gy-5 gy-lg-0 align-items-lg-center">
                        <div className="col-12 col-lg-12">
                            <div className="row justify-content-center">
                                <div className="col-12 col-xl-11">
                                    <div className="accordion accordion-flush" id="accordionExample">

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Why Should You Choose QGB gold For Selling My Gold?
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>QGB is the perfect place to sell gold online as we offer doorstep service for exchanging your gold for cash; our process is 100% transparent and follows scientific valuation methods that provide the highest price for your gold.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Is the Transaction Amount Only Paid Via Cash?
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    We pay for the transactions to your bank account via online transfers.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    How Is Valuation Done And How Long Does It Take?
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>Valuation is done based on a purity check. We use german spectrometers and precision weighing scales. This takes a maximum of 15 minutes.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                                    How is Gold QGB Different From Others?
                                                </button>
                                            </h2>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    QGB.com differs from other gold sellers as we offer a quality-centric valuation process based on purity checks. We use german spectrometers and even precision weighing scales wherein the entire process lasts about 15 minutes.

                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                                                    What Is The Process Time To Complete The Transactions Once Request Placed Online?
                                                </button>
                                            </h2>
                                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    The gold purity check and KYC takes 10-15 minutes. Then the transfer is immediate.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseThree">
                                                    Why Should I Choose QGB Gold to Sell my Gold?
                                                </button>
                                            </h2>
                                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>If you want cash for gold, QGB Gold Point, your ideal solution to sell gold at fair prices. Forget about the old gold buyers and come to get better deals. Reasons to visit QGB Golf Point-:</p>
                                                    <ul>
                                                        <li>Better valuation of gold </li>
                                                        <li>100% transparent process</li>
                                                        <li>Multi-level scientific testing for weight and purity</li>
                                                        <li>Free test for your gold</li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseThree">
                                                    How Much Do Gold Buyers Pay For Gold?
                                                </button>
                                            </h2>
                                            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    When you go to old gold buyers to sell gold for cash, you will hardly get half of your gold’s worth. Hence, it is beneficial to look for credible options to get cash for gold.
                                                </div>
                                            </div>
                                        </div>

                    

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingEight">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseThree">
                                                    How Is Valuation Done And How Long Does It Take?
                                                </button>
                                            </h2>
                                            <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    The valuation procedure to avail cash for gold at QGB is quite different and more credible than old gold buyers. We value the gold with the multi-level scientific process before you sell gold for cash.

                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingNine">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseThree">
                                                    Will My Ornaments Get Damaged Due To Testing?
                                                </button>
                                            </h2>
                                            <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    We follow a 100% transparent and safe testing process using state of the art machines and testing methods. So, your ornaments are safe when you need cash for gold.

                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTen">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseThree">
                                                    How Is Gold Price Per Gram Calculated?
                                                </button>
                                            </h2>
                                            <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    Gold price per gram is calculated by ascertaining the price of gold prevailing in the market. For instance, if the current price of gold is 46,000 for 10 grams, the per gram price will be calculated as 46,000/10 = 4,600 INR.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingEleven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseThree">
                                                    How Is Valuation Done And How Long Does It Take?
                                                </button>
                                            </h2>
                                            <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    The valuation procedure to avail cash for gold at QGB is quite different and more credible than old gold buyers. We value the gold with the multi-level scientific process before you sell gold for cash.
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FAQ