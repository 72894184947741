import React from 'react';
import Home from '../component/home';
import About from '../component/about';
import Workprocess from '../component/workprocess';
import Calculate from '../component/calculategold';
import WhyChoose from '../component/whyChoose';
import HowWork from '../component/howWork';
import Services from '../component/services';
import Location from '../component/location';
import FAQ from '../component/faq';
import Lastsection from '../component/lastsection';

function Main() {
  return (
    <>
      <Home />
      <About />
      <Workprocess />
      <Calculate />
      <WhyChoose />
      <HowWork/>
      <Services/>
      <Location/>
      <FAQ/>
      <Lastsection/>
    </>
  );
}

export default Main;
