import React from "react";
import Mapimage from '../images/box.png'
import { FaGrinHearts, FaStar } from "react-icons/fa";
import { GiGemNecklace } from "react-icons/gi";

const WhyChoose = () => {
    window.addEventListener(
        "scroll",
        () => {
            document.body.style.setProperty(
                "--scroll",
                window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
            );
        },
        false
    );
    return (
        <>
            <div className="container mt-5" id="whychoose">
                <div className=" d-flex justify-content-center">
                    <h1 className="All-heading">Why Choose Us??</h1>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-12 text-center">
                        <p style={{ margin: '0 20px' }}>
                            At Quick Gold Buyer, we understand the value of your precious assets. That's why we've crafted a seamless and secure process to buy your old gold, combining cutting-edge KYC software with a commitment to hassle-free transactions.
                        </p>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-12 text-center">
                        <h6><b>Your Trust, Our Priority:</b></h6>
                        <p style={{ margin: '10px 20px' }}>
                            At Quick Gold Buyer, we prioritize your trust and satisfaction. Our commitment to innovation, transparency, and security sets us apart in the gold buying industry. Join countless satisfied customers who have experienced the ease of selling their old gold with us. Ready to turn your old gold into cash? Start the process today with Quick Gold Buyer.
                        </p>
                    </div>
                </div>
            </div>

            <section class="page-section mt-5" id="about">
                <div class="container">
                    {/* <div className=" d-flex justify-content-center">
                        <h1 className="All-heading">Why Choose Us??</h1>
                    </div> */}

                    <ul class="timeline mt-5">
                        <li>
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src="https://t4.ftcdn.net/jpg/00/71/67/87/360_F_71678766_kPinbw5YXRSJrlwwT8SmA90TgjBu64Ng.jpg" alt="..." /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="subheading"> 1. Innovative KYC Software:</h4>
                                </div>
                                <div class="timeline-body"><p class="text-muted">Our state-of-the-art KYC software ensures a swift and secure verification process. Your personal information is handled with the utmost confidentiality, and you can complete the entire transaction online, eliminating the need for any physical paperwork.</p></div>
                            </div>
                        </li>
                        <li class="timeline-inverted">
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src="https://t4.ftcdn.net/jpg/00/71/67/87/360_F_71678766_kPinbw5YXRSJrlwwT8SmA90TgjBu64Ng.jpg" alt="..." /></div>

                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="subheading">2. Transparent Transactions</h4>
                                </div>
                                <div class="timeline-body"><p class="text-muted">
                                    We believe in transparency in every step of the process. Our online platform provides real-time updates, allowing you to track your transaction from start to finish. And You will get digital receipt with your value</p></div>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src="https://t4.ftcdn.net/jpg/00/71/67/87/360_F_71678766_kPinbw5YXRSJrlwwT8SmA90TgjBu64Ng.jpg" alt="..." /></div>

                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="subheading">3. Competitive Rates:</h4>
                                </div>
                                <div class="timeline-body"><p class="text-muted">
                                    Get the best value for your old gold with our competitive rates. Our team of experts stays up-to-date with market trends to offer you a fair and honest appraisal.
                                </p></div>
                            </div>
                        </li>
                        <li class="timeline-inverted">
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src="https://t4.ftcdn.net/jpg/00/71/67/87/360_F_71678766_kPinbw5YXRSJrlwwT8SmA90TgjBu64Ng.jpg" alt="..." /></div>

                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="subheading">4. No Physical Written Forms:</h4>
                                </div>
                                <div class="timeline-body"><p class="text-muted">Say goodbye to the hassle of paperwork. We've streamlined the process, and there's no need for any physical written forms. Our user-friendly online platform guides you through the required steps effortlessly.</p></div>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src="https://t4.ftcdn.net/jpg/00/71/67/87/360_F_71678766_kPinbw5YXRSJrlwwT8SmA90TgjBu64Ng.jpg" alt="..." /></div>

                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="subheading">5. Secure Payment Options:</h4>
                                </div>
                                <div class="timeline-body"><p class="text-muted"> Receive your payment quickly and securely. We offer a variety of payment options Like UPI , Bank Transfer , Google Pay to suit your preferences, ensuring a convenient and efficient experience.</p></div>
                            </div>
                        </li>
                        {/* <li class="timeline-inverted">
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src="https://t4.ftcdn.net/jpg/00/71/67/87/360_F_71678766_kPinbw5YXRSJrlwwT8SmA90TgjBu64Ng.jpg" alt="..." /></div>

                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="subheading">6. Secure Payment Options:</h4>
                                </div>
                                <div class="timeline-body"><p class="text-muted">Receive your payment quickly and securely. We offer a variety of payment options Like UPI , Bank Transfer , Google Pay to suit your preferences, ensuring a convenient and efficient experience.</p></div>
                            </div>
                        </li> */}
                        <li class="timeline-inverted">
                            <div class="timeline-image">
                                <h4>
                                    Be Part
                                    <br />
                                    Of Our
                                    <br />
                                    Story!
                                </h4>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default WhyChoose